export function money(val) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(val))
}

export function formatNumber(n) {
  if (n < 1e3) return n.toFixed(2);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export function formattedMoney(n) {
    return `R$ ${formatNumber(n)}`
}

export const methodParser = {
    cash: 'Dinheiro',
    credit_pinpad: 'Crédito - BymaPad',
    debit_pinpad: 'Débito - BymaPad',
    credit_external: 'Crédito - Máquina',
    debit_external: 'Débito - Máquina',
    online_credit: 'Crédito - Online',
    online_picpay: 'Picpay - Online',
    online_pix: 'Pix - Online',
    online_ame: 'Ame - Online',
    online_nupay: 'NuPay - Online',
    payment_link: 'Link de pagamento',
    free: 'Gratuito',
    payable_order_credit: 'Crédito - QRCode',
    pos_pix: 'Pix - Presencial',
    adyen_pos_credit: 'Crédito - Terminal',
    adyen_pos_debit: 'Débito - Terminal',
    adyen_pos_pix: 'Pix - Terminal',
}

export const statusParser = {
    paid: 'Pago',
    pending: 'Pendente',
    canceled: 'Cancelado',
    refunded: 'Cancelado',
    expired: 'Expirado',
    refused: 'Não autorizado',
    errored: 'Erro',
    'order placed': 'Pendente',
    POS: 'Pago',
    under_analysis: 'Sob análise',
}

export const anticipationStatusParser = {
    pending: 'Processando',
    requested: 'Solicitada',
    refused: 'Negada',
    approved: 'Aprovada',
}

export const withdrawalStatusParser = {
    pending: 'Processando',
    requested: 'Solicitado',
    refused: 'Negado',
    approved: 'Aprovado',
}

export const receivableTypeParser = {
    other: 'Outro',
    anticipation: 'Antecipação',
    bonus: 'Bônus',
    final: 'Disponibilização final',
}
export const refusalReasonCodeParser = {
    2: 'Transação recusada',
    4: 'Transação não processada por um erro da adquirente',
    5: 'Cartão bloqueado',
    6: 'Cartão expirado',
    7: 'Valor inválido', 
    8: 'Número do cartão inválido',
    9: 'Não foi possível contatar o emissor ou o banco',
    10: 'Transação não suportada pelo banco/emissor',
    11: 'Transação não passou pela validação 3DS',
    12: 'Saldo insuficiente',
    15: 'Transação cancelada',
    16: 'Transação cancelada pelo comprador',
    17: 'PIN inválido',
    22: 'Transação não permitida', // Fraud-Cancelled
    23: 'Transação não permitida',
    24: 'Código de verificação do cartão inválido',
    25: 'Cartão com restrição',
    27: 'Transação recusada',
    32: 'Endereço inválido',
    33: 'Cartão precisa de senha',
    36: 'Senha móvel necessária',
    38: 'Autenticação necessária',
    42: 'Autenticação 3DS falhou',
}
