import { useEffect, useMemo } from 'react'
import Input from '@byma/shared/components/Input'
import { FiArrowRight, FiCalendar, FiX } from 'react-icons/fi'
import dayjs from 'dayjs'
import React from 'react'
import Router from 'next/router'

const formatString = 'DD/MMM/YYYY'
const dateFilterOptions = [{
    key: '1d',
    title: 'Hoje',
    fromDate: dayjs().startOf('day').format(formatString),
    toDate: dayjs().endOf('day').format(formatString),
}, {
    key: '7d',
    title: 'Últimos 7 dias',
    fromDate: dayjs().subtract(6, 'days').startOf('day').format(formatString),
    toDate: dayjs().endOf('day').format(formatString),
}, {
    key: '30d',
    title: 'Últimos 30 dias',
    fromDate: dayjs().subtract(29, 'days').startOf('day').format(formatString),
    toDate: dayjs().endOf('day').format(formatString),
}, {
    key: '60d',
    title: 'Últimos 60 dias',
    fromDate: dayjs().subtract(59, 'days').startOf('day').format(formatString),
    toDate: dayjs().endOf('day').format(formatString),
}, {
    key: 'all',
    title: 'Tudo',
    fromDate: dayjs().set('year', 2000).startOf('day').format(formatString),
    toDate: dayjs().endOf('day').format(formatString),
}, {
    key: 'cd',
    title: 'Período customizado',
}]

export default function DateFilter({activeKey}) {
    const [dateFilterOpen, setDateFilterOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState({
        activeKey,
        fromDate: dayjs().toISOString(),
        toDate: dayjs().toISOString(),
    })

    useEffect(() => {
        setSelectedOption({
            ...selectedOption,
            activeKey,
        })
    }, [activeKey])

    function handleFilter() {
        let url = new URL(window.location.href)
        let searchParams = url.searchParams
        searchParams.set('d', selectedOption.activeKey)
        if (selectedOption.activeKey === 'cd') {
            searchParams.set('fd', dayjs(selectedOption.fromDate).toISOString())
            searchParams.set('td', dayjs(selectedOption.toDate).toISOString()) 
        }
        url.search = searchParams.toString()
        Router.push(url.toString())
        setDateFilterOpen(false)
    }


    const activeFilter = useMemo(() => dateFilterOptions.find(d => d.key === activeKey) || dateFilterOptions[1], [activeKey])

    return (
        <div className="">
            <button className="flex justify-between items-center cursor-pointer rounded shadow px-2 py-1 text-sm hover:shadow-md whitespace-nowrap space-x-1 focus:shadow-sm" onClick={() => setDateFilterOpen(!dateFilterOpen)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                </svg>
                { activeFilter.key === 'cd' ? (
                    <div className="flex space-x-1">
                        <p>{dayjs(selectedOption.fromDate).format('DD/MM/YY HH:mm') }</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                        <p>{dayjs(selectedOption.toDate).format('DD/MM/YY HH:mm') }</p>
                    </div>
                ) : (
                    <p>{activeFilter.title}</p>
                )}
            </button>
            <div className={`fixed w-full h-full inset-0 transition-opacity duration-100 ${dateFilterOpen ? 'z-50 opacity-100' : '-z-1 opacity-0'}`} onClick={() => setDateFilterOpen(false)}>
                <div class="absolute w-full h-full bg-gray-900 opacity-50" />
                <div class="modal-content p-3 absolute bg-white w-full md:w-11/12 md:max-w-md md:mx-auto md:rounded md:shadow-lg z-50 overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                    <div className="flex justify-between items-center mb-6">
                        <div className="">
                            <p className="font-bold text-xl">Filtrar por per&iacute;odo</p>
                        </div>
                        <div className="cursor-pointer p-3" onClick={() => setDateFilterOpen(false)}>
                            <FiX size={24}/>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3">
                        {dateFilterOptions.map(option => (
                            <div className={`flex whitespace-no-wrap items-center rounded shadow border-red-200 p-3 space-x-3 hover:shadow-md cursor-pointer ${selectedOption.activeKey === option.key ? 'outline outline-2 outline-red-300' : ''}`} onClick={() => setSelectedOption({...selectedOption, activeKey: option.key})}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`${selectedOption.activeKey === option.key ? 'text-red-500' : 'text-gray-900'} h-5 w-5`} viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                                </svg>
                                <div>
                                    <p>{option.title}</p>
                                    {option.key !== 'all' && option.key !== 'cd' &&
                                        <div className="flex items-center gap-1 text-gray-700 whitespace-no-wrap text-sm">
                                            {option.fromDate}
                                            <FiArrowRight />
                                            {option.toDate}
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                        { selectedOption.activeKey === 'cd' && (
                            <div className="flex flex-col space-y-3 box">
                                <Input
                                    label={'Início'}
                                    value={selectedOption.fromDate}
                                    max={dayjs().format('YYYY-MM-DDTHH:mm:ss')}
                                    onChange={(event) => setSelectedOption({...selectedOption, fromDate: event.target.value})}
                                    type="datetime-local"
                                />
                                <Input
                                    label={'Fim'}
                                    value={selectedOption.toDate}
                                    min={selectedOption.fromDate}
                                    max={dayjs().format('YYYY-MM-DDTHH:mm:ss')}
                                    onChange={(event) => setSelectedOption({...selectedOption, toDate: event.target.value})}
                                    type="datetime-local"
                                />
                            </div>
                        )}
                        <button className="btn btn-primary w-full" onClick={handleFilter}>
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}
