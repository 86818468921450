import { FiBox, FiX } from 'react-icons/fi'
import Router from 'next/router'
import useSWR from 'swr'
import dayjs from 'dayjs'
import fetcher from '@byma/shared/helpers/fetcher'
import Loader from '@byma/shared/components/Loader'
import Input from '@byma/shared/components/Input'
import React from 'react'
import UserProfilePicture from '@byma/shared/components/UserProfilePicture'

function ChannelItem({channel, active=false}) {
    return (
        <div className={`flex items-center cursor-pointer box box-hover ${active && 'box-active'} gap-3`}>
            <input type="checkbox" checked={active} readOnly />
            <UserProfilePicture user={channel.userId} size="12" />
            <div className="">
                <p className="text-md font-bold">{channel?.userId?.profile?.name}</p>
                <p className="text-sm">{channel?.userId?.email}</p>
            </div>
        </div>
    )
}

export default function ChannelFilter({ token, filteredChannels=[] }) {
    const [channelFilterOpen, setChannelFilterOpen] = React.useState(false);
    const [selectedChannels, setSelectedChannels] = React.useState([])

    const { data: promoters, error } = useSWR(token ? [`/api/promoters/getAll?perPage=1000&distinct=true`, null, token] : null, fetcher)

    React.useEffect(() => {
        if (filteredChannels) {
            setSelectedChannels(typeof filteredChannels === 'string' ? [filteredChannels] : filteredChannels)
        }
    }, [filteredChannels])

    function handleSelect(channel) {
        if (selectedChannels.indexOf(channel.userId._id) >= 0) {
            setSelectedChannels(selectedChannels.filter(e => e !== channel.userId._id))
        } else {
            setSelectedChannels([...selectedChannels, channel.userId._id])
        }
    }

    function handleFilter() {
        let url = new URL(window.location.href)
        let searchParams = url.searchParams
        searchParams.delete('c')
        selectedChannels.forEach(event => searchParams.append('c', event))
        url.search = searchParams.toString()
        Router.push(url.toString())
        setChannelFilterOpen(false)
    }

    return (
        <div className="">
            <button className="flex justify-between items-center cursor-pointer rounded shadow px-2 py-1 text-sm hover:shadow-md whitespace-nowrap space-x-1 focus:shadow-sm" onClick={() => setChannelFilterOpen(!channelFilterOpen)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd" />
                </svg>
                {selectedChannels.length > 0 ? (
                    <p>
                        {selectedChannels.length} cana{selectedChannels.length > 1 ? 'is' : 'l'}
                    </p>
                ) : (
                    <p>
                        Todos os canais de venda
                    </p>
                )}
            </button>
            <div className={`fixed w-full h-full inset-0 transition-opacity duration-100 ${channelFilterOpen ? 'z-50 opacity-100' : '-z-1 opacity-0'}`} onClick={() => setChannelFilterOpen(false)}>
                <div class="absolute w-full h-full bg-gray-900 opacity-50" />
                <div class="modal-content absolute bg-white w-full md:w-11/12 md:max-w-md md:mx-auto md:rounded md:shadow-lg z-50 md:h-3/4 h-full" onClick={(e) => e.stopPropagation()}>
                    <div className="flex flex-col justify-between p-3 space-y-3 h-full">
                        <div className="">
                            <div className="flex justify-between items-center mb-3">
                                <div className="">
                                    <p className="font-bold text-xl">Filtrar por canal de venda</p>
                                </div>
                                <div className="cursor-pointer" onClick={() => setChannelFilterOpen(false)}>
                                    <FiX size={24}/>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-y-auto flex-grow flex-shrink">
                            <div className="flex flex-col">
                                { promoters ? promoters.result.map(channel => (
                                    <div onClick={() => handleSelect(channel)}>
                                        <ChannelItem channel={channel} active={selectedChannels.indexOf(channel.userId._id) >=0} />
                                    </div>
                                )) : <Loader />}
                            </div>
                        </div>
                        <div className="flex space-x-2">
                            {selectedChannels.length === 0 ? (
                                <button className="btn btn-primary w-full" onClick={handleFilter}>Remover filtro de canais</button>
                            ) : (
                                <>
                                    <button className="btn btn-primary w-full" onClick={handleFilter}>Filtrar {selectedChannels.length} cana{selectedChannels.length > 1 ? 'is' : 'l'}</button>
                                    <button className="btn btn-white w-full" onClick={() => setSelectedChannels([])}>Limpar sele&ccedil;&atilde;o</button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
