export default function UserProfilePicture({user, size='12'}) {
    return user?.profile?.picture ? (
        <img 
            src={user.profile.picture} 
            className={`rounded-full w-${size} h-${size} object-cover`}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src="https://res.cloudinary.com/htkavmx5a/image/upload/c_thumb,fl_progressive.lossy,g_face,h_200,q_35,w_200/ywjsiajwosquwwrpewuo"
            }}
        />
    ) : (
        <div 
            className={`w-${size} h-${size} rounded-full bg-gray-400 overflow-hidden`}
        />
    ) 
}
